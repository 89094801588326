<template>
	<div>
		<cui-forgot-password />
	</div>
</template>
<script>
import CuiForgotPassword from '@/components/system/Auth/ForgotPassword'
export default {
	components: {
		CuiForgotPassword,
	},
}
</script>
