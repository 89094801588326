<template>
	<a-spin :tip="loadingLabel" size="large" :spinning="loading">
		<div class="card" :class="$style.container">
			<div class="text-dark font-size-24 mb-4">
				<strong>{{ $t('system.auth.forgotPassword.header') }}</strong>
			</div>
			<a-form class="mb-4" :form="form" @submit="handleSubmit">
				<a-form-item>
					<a-input
						size="large"
						:placeholder="$t('system.auth.forgotPassword.form.username.placeholder')"
						v-decorator="['username', { rules: [{ required: true, message: $t('system.auth.forgotPassword.form.username.required') }] }]"
					/>
				</a-form-item>
				<a-button type="primary" htmlType="submit" size="large" class="text-center w-100" :loading="loading">
					<strong>{{ $t('system.auth.forgotPassword.button') }}</strong>
				</a-button>
			</a-form>
			<router-link to="/auth/login" class="kit__utils__link font-size-16">
				<i class="fe fe-arrow-left mr-1 align-middle" />
				{{ $t('system.auth.forgotPassword.backLabel') }}
			</router-link>
		</div>
	</a-spin>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'CuiForgotPassword',
	computed: {
		...mapGetters('auth', ['loading', 'loadingLabel']),
	},
	data: function () {
		return {
			form: this.$form.createForm(this),
		}
	},
	methods: {
		handleSubmit(e) {
			e.preventDefault()
			this.form.validateFields((err, values) => {
				if (!err) {
					this.$store.dispatch('auth/RECOVERY_PASSWORD', { ...values })
				}
			})
		},
	},
}
</script>
<style lang="scss" module>
@import '@/components/system/Auth/style.module.scss';
</style>
